import { Select, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { Table } from 'antd';
import {
  ExpandComponentWrapper,
  HeadingStyled,
  TableStyled,
  ExpandListingInfoInProductsTableWrapper,
} from 'styles/style';

import {
  LISTING_STATUS,
  LISTING_STATUS_COLORS,
} from 'silal_app_base_react/src/config/constants';
import { actionUpdateListingStockAndPrice } from 'store/products/products_actions';
import ListingsRepository from 'data/repositories/listings_repository';
import { getCurrentStore } from 'core/hooks/use_selector';

import {
  backArrowIcon_path,
  productPlaceholder_path,
  notFoundIllustration_path,
} from 'assets/index';
import {
  CURRENT_CURRENCY,
  LISTING_STATUS_TYPES,
} from 'silal_app_base_react/src/config/constants';
import {
  fetchCategoryListings,
  getExpandableTableBody,
  getExpandableTableHeaders,
  getMinMaxPrice,
} from '../functions/products_functions';
import {
  ListingItem,
  Listing,
} from 'silal_app_base_react/src/data/types/listings';
import { toast } from 'react-toastify';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { RootState } from 'data/types/state';
import { ConfigProvider } from 'antd';
import { ListingActionButtons } from 'silal_app_base_react/src/pages/listing_profile/components/action_buttons';
import Lightbox from 'react-image-lightbox';
import { ImageType } from 'silal_app_base_react/src/data/types/files';

const ProductsSubmitted = () => {
  const { categoryId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentStore = useSelector(getCurrentStore);
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [items, setListings] = useState<Listing[]>([]);

  const [image, setImage] = useState<ImageType | null>(null);
  const handleClose = () => {
    setImage(null);
  };

  const [currentRow, setCurrentRow] = useState<Listing>();
  const [selectedFilters, setSelectedFilters] = useState(new Set());
  const [fullListing, setFullListing] = useState<Record<number, Listing>>({});
  const [loading, setLoading] = useState(false);

  const [filteredItems, setFilteredItems] = useState<Listing[]>([]); // TODO fix any
  const [options] = useState([
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.pending_review as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.pending_review,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.listed as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.listed,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.freezed_by_management as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.freezed_by_management,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.freezed_by_seller as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.freezed_by_seller,
    },
    {
      label:
        LISTING_STATUS_TYPES[
          LISTING_STATUS.rejected as keyof typeof LISTING_STATUS_TYPES
        ],
      value: LISTING_STATUS.rejected,
    },
  ]);

  useEffect(() => {
    if (categoryId && isNaN(parseInt(categoryId!))) {
      window.location.replace('/products');
      toast.warn('Invalid category id');
      return;
    }
    if (!location.state) {
      fetchCategoryListings(
        setListings,
        currentStore.id,
        categoryId,
        setLoading,
      );
      return;
    }
    setListings(location.state);
  }, [location]);

  useEffect(() => {
    if (currentRow) {
      getListing(currentRow.id);
    }
  }, [currentRow]);

  useEffect(() => {
    // TODO fix, empties on refresh
    const filteredItems = () => {
      const filteredItems = items
        ?.filter((item) => {
          if (selectedFilters.size === 0) return true; // Return true for items without a filter
          const status = item.listing_status_num;
          if (
            selectedFilters.has(LISTING_STATUS.listed) &&
            status == LISTING_STATUS.listed
          )
            return true;
          if (
            selectedFilters.has(LISTING_STATUS.freezed_by_management) &&
            status == LISTING_STATUS.freezed_by_management
          )
            return true;

          if (
            selectedFilters.has(LISTING_STATUS.freezed_by_seller) &&
            status == LISTING_STATUS.freezed_by_seller
          )
            return true;

          if (
            selectedFilters.has(LISTING_STATUS.rejected) &&
            status == LISTING_STATUS.rejected
          )
            return true;

          if (
            selectedFilters.has(LISTING_STATUS.pending_review) &&
            status == LISTING_STATUS.pending_review
          )
            return true;

          return false; // Return false for items that don't meet any conditions
        })
        .sort((b, a) => {
          return moment(a.date_created).unix() - moment(b.date_created).unix();
        });
      return filteredItems?.filter((item) => {
        if (searchQuery === '') return true; // Return true for items without a search query
        if (
          item.name_en.toLowerCase().match(searchQuery.toLowerCase()) ||
          item.name_ar.toLowerCase().match(searchQuery.toLowerCase()) ||
          item.id.toString().match(searchQuery.toLowerCase())
        ) {
          return true;
        }
        return false; // Return false for items that don't match search
      });
    };
    setFilteredItems(filteredItems);
  }, [items, selectedFilters, searchQuery]);

  const getListing = async (listing_id: number) => {
    await ListingsRepository.getListingWithID(currentStore.id, listing_id).then(
      (listing) => {
        if (!listing) return;
        setFullListing((prev) => ({ ...prev, [listing.id]: listing }));
      },
    );
  };
  const columns = [
    {
      title: 'Photo',
      dataIndex: 'images',
      key: 'photo',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value
      render: (_: unknown, row: Listing) => {
        const img_i = row.images.findIndex(
          (img) => img.id === row?.cover_image_id,
        );
        return row.images.length > 0 ? (
          <ShowImage
            image={row.images[img_i]}
            style={{ width: '100px', height: '100px', objectFit: 'scale-down' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setImage(row.images[img_i]);
            }}
          />
        ) : (
          <img src={productPlaceholder_path} alt="Placeholder" />
        );
      },
    },
    {
      title: 'Item Name',
      dataIndex: 'name_en',
      key: 'item_name',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value
      render: (_: unknown, row: Listing) => (
        <>
          #{row.id} {row.name_en} <br />
          {row.name_ar}
        </>
      ),
      sorter: (a: Listing, b: Listing) =>
        a.name_en.localeCompare(b.name_en) ||
        a.name_ar.localeCompare(b.name_ar),
    },

    {
      title: 'Category',
      key: 'category',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value
      render: (_: unknown, row: Listing) =>
        minimalCategoriesList?.find((x) => x.id === row.category_id)?.name_en,
      sorter: (a: Listing, b: Listing) =>
        minimalCategoriesList
          ? minimalCategoriesList
              ?.find((x) => x.id === a.category_id)
              ?.name_en.localeCompare(
                minimalCategoriesList?.find((x) => x.id === b.category_id)
                  ?.name_en || '',
              )
          : b.category_id - a.category_id,
    },
    {
      title: 'Variations',
      key: 'variations',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value

      render: (_: unknown, row: Listing) => row.items.length,
    },
    {
      title: 'Submitted On',
      dataIndex: 'date_created',
      key: 'submitted_on',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value
      render: (date: string) => formateServerDateToLocaleDate(date),
      sorter: (a: Listing, b: Listing) =>
        moment(a.date_created).unix() - moment(b.date_created).unix(),
    },
    {
      title: 'Price',
      key: 'price',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value
      render: (_: unknown, row: Listing) =>
        row.items?.length > 0
          ? `${CURRENT_CURRENCY}${getMinMaxPrice(row.items)}`
          : `${CURRENT_CURRENCY}0`,
      sorter: (a: Listing, b: Listing) =>
        parseFloat(getMinMaxPrice(a.items).toString()) -
        parseFloat(getMinMaxPrice(b.items).toString()),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value
      render: (_: unknown, row: Listing) => (
        <Tag
          color={
            LISTING_STATUS_COLORS[
              row.listing_status_num as keyof typeof LISTING_STATUS_COLORS
            ]
          }
          style={{ borderRadius: '5px', color: '#fff' }}
        >
          {
            LISTING_STATUS_TYPES[
              row.listing_status_num as keyof typeof LISTING_STATUS_TYPES
            ]
          }
        </Tag>
      ),
      sorter: (a: Listing, b: Listing) =>
        a.listing_status_num - b.listing_status_num,
    },
    {
      title: 'Actions',
      key: 'edit',
      align: 'center' as const, // Explicitly asserts 'center' as a valid value

      render: (_: unknown, row: Listing) => (
        <ListingActionButtons
          showDeleteButton={true}
          showHideButton={true}
          showDetailsButton={true}
          listing={row}
          ListingsRepository={ListingsRepository}
          store_id={currentStore.id}
          onHideListingSuccess={(listingId: number) => {
            const newItems: Listing[] | undefined = items?.map((x) => {
              if (x.id === listingId) {
                return {
                  ...x,
                  freezed_by_seller: !x.freezed_by_seller,
                  listing_status_num: LISTING_STATUS.changed,
                };
              }
              return x;
            });
            setListings(newItems?.filter((i) => i));
          }}
        />
      ),
    },
  ];

  const tagRender = (props: any) => {
    const { label, value } = props;
    const onClose = () => {
      const tmp = selectedFilters;
      tmp.delete(value);
      setSelectedFilters(tmp);
    };
    return (
      <Tag
        color={
          LISTING_STATUS_COLORS[value as keyof typeof LISTING_STATUS_COLORS]
        }
        // onMouseDown={onPreventMouseDown}
        closable={false}
        onClose={onClose}
        style={{
          marginRight: 3,
          borderRadius: '5px',
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleChangeInStock = (item: ListingItem, data: Listing) => {
    const payload = {
      data: {
        items: [
          { item_id: item.id, price: item.price, in_stock: !item.in_stock },
        ],
      },
      success: (response: any) => {
        // TODO fix any
        if (response && response.updated_items) {
          const updatedItem = response.updated_items[0];
          const newItems = items.map((x) => {
            if (x.id === data.id) {
              const updateIndex = data.items.findIndex(
                (dataItem) => dataItem.id === updatedItem.id,
              );
              data.items.splice(updateIndex, 1, updatedItem);
              return data;
            }
            return x;
          });

          setListings(newItems.filter((i) => i));
        }
      },
    };
    dispatch(actionUpdateListingStockAndPrice(payload));
  };

  return (
    <Wrapper>
      <>
        {image && (
          <Lightbox
            mainSrc={(image as ImageType).public_s3_url}
            imageTitle={(image as ImageType).alt || 'No Alt Text'}
            mainSrcThumbnail={(image as ImageType).public_s3_url}
            onCloseRequest={handleClose}
          />
        )}
        <div className="d-flex align-items-center">
          <button onClick={() => navigate(-1)} className="back-arrow ">
            <img src={backArrowIcon_path} />
          </button>
          <HeadingStyled
            style={{
              fontSize: '25px',
              fontWeight: '700',
            }}
          >
            Submitted
          </HeadingStyled>
          <div className="d-flex w-100  justify-content-center ">
            <SearchBar className="mx-5 mt-4">
              <Form
                as="input"
                onChange={(e: any) => setSearchQuery(e.target.value)} // TODO fix any
                type="text"
                className="bg-white"
                placeholder="Search"
              />
              <Search className="icon-label position-absolute" size={17} />
            </SearchBar>
          </div>
          <div className="d-flex">
            <p className="dark-clr secondary-font px-3">
              Here you can find the listings you’ve submitted and currently
              under review by Silal team.
            </p>
          </div>
        </div>
        <Row>
          <Col md={1}>
            <p
              className="dark-clr secondary-font text-center py-1"
              style={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              Status
            </p>
          </Col>
          <Col md={3}>
            <Select
              mode="multiple"
              placeholder={'All'}
              tagRender={tagRender}
              onChange={(val: any) => {
                setSelectedFilters(new Set(val));
              }}
              style={{
                width: '100%',
              }}
              options={options}
            />
          </Col>
        </Row>

        <div className="template-section mt-4">
          <div className="">
            <TableStyled>
              {filteredItems?.length === 0 ? (
                <div className="no-orders">
                  {loading ? (
                    <>
                      <Spinner />
                      <h3>Loading from server...</h3>
                    </>
                  ) : (
                    <>
                      <img src={notFoundIllustration_path} alt="no-orders" />
                      <h3>No submitted listings were found</h3>
                    </>
                  )}
                </div>
              ) : (
                <Table
                  size="middle"
                  columns={columns as any} // Your Ant Design columns configuration
                  dataSource={filteredItems} // Data to display
                  rowKey="id" // Unique key for rows
                  pagination={{
                    defaultPageSize: 50,
                    showSizeChanger: true,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['50', '100', '500', '1000', '2000'],
                  }}
                  expandable={{
                    expandedRowRender: (record) => {
                      // Replace with your expandable row content logic
                      const ExpandedRow = ExpandedComponent(
                        handleChangeInStock,
                        fullListing,
                      );
                      return <ExpandedRow data={record} />;
                    },
                    rowExpandable: (record) => !!record, // Ensure row is expandable based on your condition
                    onExpand: (_, record) => setCurrentRow(record), // Track expanded row
                    expandRowByClick: true,
                  }}
                  onRow={(record) => ({
                    onClick: () => setCurrentRow(record), // Handle row click
                  })}
                />
              )}
            </TableStyled>
          </div>
        </div>
      </>
    </Wrapper>
  );
};

// This is used only here, although it's almost duplicate from the one in products_page, it differes in the fact that it includes the silal response
const ExpandedComponent = (
  handleChangeInStock: any,
  fullListing: Record<number, Listing> | undefined,
) => {
  const ExpandedComponentWrapper = ({ data }: { data: Listing }) => {
    const currentImages = data?.items.map((item) => item.image).flat();
    const [index, setIndex] = useState(-1);
    const image = currentImages?.[index];
    const currentImage = currentImages?.[index];
    const nextIndex = (index + 1) % currentImages?.length;
    const nextImage = currentImages?.[nextIndex] || currentImage;
    const prevIndex =
      (index + currentImages?.length - 1) % currentImages?.length;
    const prevImage = currentImages?.[prevIndex] || currentImage;
    const handleClick = (index: number) => setIndex(index);
    const handleClose = () => {
      setIndex(-1);
    };
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
    return (
      <>
        {image && (
          <Lightbox
            mainSrc={image.public_s3_url}
            imageTitle={`Image #${currentImages[index].id} for item #${data.items[index].id}`}
            mainSrcThumbnail={image.public_s3_url}
            nextSrc={nextImage.public_s3_url}
            nextSrcThumbnail={nextImage.public_s3_url}
            prevSrc={prevImage.public_s3_url}
            prevSrcThumbnail={prevImage.public_s3_url}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
        <ExpandComponentWrapper>
          <div className="">
            <ExpandListingInfoInProductsTableWrapper key={data.id}>
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBg: '#05AE4B',
                      colorTextHeading: '#fff',
                    },
                  },
                }}
              >
                <Table
                  columns={getExpandableTableHeaders(data)}
                  footer={() => (
                    <h6 style={{ color: 'black' }}>
                      {fullListing && getSilalResponse(fullListing[data.id])}
                    </h6>
                  )}
                  dataSource={getExpandableTableBody(
                    data,
                    handleChangeInStock,
                    handleClick,
                  )}
                  pagination={false}
                  size="small"
                />
              </ConfigProvider>
            </ExpandListingInfoInProductsTableWrapper>
          </div>
        </ExpandComponentWrapper>
      </>
    );
  };

  // Add the displayName property to the inner component function
  ExpandedComponentWrapper.displayName = 'ExpandedComponent';

  return ExpandedComponentWrapper;
};

const getSilalResponse = (fullListing: Listing) => {
  if (fullListing?.request?.response?.msg) {
    return (
      <Row className="silal-response">
        <Col md={4} className="p-2">
          <p className="bold">
            Silal quality team comment for item #{fullListing.id}:
          </p>
        </Col>
        <Col md={8} className="p-2">
          <p className="message">{fullListing.request.response.msg}</p>
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
};

export default ProductsSubmitted;

const SearchBar = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 26px;
  input {
    padding-left: 40px;
    width: 100%;
    padding-right: 20px;
    border: 1px solid rgba(204, 212, 214, 0.5);
    border-radius: 5px;
    height: 36px;
    &,
    &::placeholder {
      font-weight: 500;
      font-size: 13px;
      color: #b3bec2;
    }
  }
  .icon-label {
    color: #b3bec2;
    left: 0;
    top: 9px;
    left: 13px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  padding: 1rem 0;
  .search-result-section {
    .accordian-wrapper {
      background: #fff;
      padding: 1rem;
      border-radius: 5px;
    }
  }

  .silal-response {
    background: var(--app-background-color);
    min-height: 20px;
    font-weight: bold;
    border-radius: 10px;
    .bold {
    }
    .message {
      color: #abb7bd;
    }
  }
`;
