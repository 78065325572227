import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import { HeadingStyled } from 'styles/style';
import { ToggleBtn } from 'pages/profile/components';
import { ToLabel } from 'pages/profile/screens/opening_hours.styled';
import { timeOptions } from 'core/constants/working_hours';

type ProfileWorkingHourProps = {
  item: any;
  handleWorkingTimes: any;
  handleWorkingTimesMultiple: any;
  formData: any;
};

export default function ProfileWorkingHour({
  item,
  handleWorkingTimes,
  handleWorkingTimesMultiple,
  formData,
}: ProfileWorkingHourProps) {
  const getOptions = () => {
    return timeOptions.filter((time) => {
      if (
        Number(time.value.split(':')[0]) - 1 >= Number(start.split(':')[0]) ||
        time.value === '23:59'
      ) {
        return time;
      }
      return null;
    });
  };

  const [open, setOpen] = useState(true);
  const [start, setStart] = useState(formData[item.values[0]]);
  const [end, setEnd] = useState(formData[item.values[1]]);

  useEffect(() => {
    if (open) {
      handleWorkingTimesMultiple([
        { value: '08:00', name: item.values[0] },
        { value: '20:00', name: item.values[1] },
      ]);
    } else {
      handleWorkingTimesMultiple([
        { value: '00:00', name: item.values[0] },
        { value: '00:00', name: item.values[1] },
      ]);
    }
  }, [open]);

  useEffect(() => {
    if (
      formData[item.values[0]] === '00:00' &&
      formData[item.values[1]] === '00:00'
    ) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);
  return (
    <>
      <Row className="align-items-center mb-20">
        <Col xs="auto" lg={6} className="between">
          <Col sm="auto" lg={6}>
            <HeadingStyled
              style={{
                fontSize: '16px',
                fontWeight: '500',
                fontFamily: Fonts.secondary,
              }}
            >
              {item.name}
            </HeadingStyled>
          </Col>{' '}
          <Col sm="auto" lg={6} className="ml-2">
            <ToggleBtn
              name="OHours"
              checked={open}
              onchange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!e.target.checked) {
                  setStart('00:00');
                  setEnd('00:00');
                } else {
                  setStart('08:00');
                  setEnd('10:00');
                }
                setOpen(e.target.checked);
              }}
              label={open ? 'Open' : 'Closed'}
            />
          </Col>
        </Col>
        <Col
          xs="auto"
          lg={6}
          className="d-flex align-items-center justify-content-around"
        >
          <Col xs="auto" lg="auto" style={{ display: 'flex' }}>
            <ToLabel>FROM</ToLabel>
            <Select
              isDisabled={end === start ? true : false}
              options={timeOptions.filter((time) => {
                if (time.value !== '23:59') return time;
                return null;
              })}
              placeholder="10:00 AM"
              value={{
                label: start,
                value: start,
              }}
              defaultValue={start}
              onChange={(val) => {
                handleWorkingTimes(val.value, item.values[0]);
                setStart(val.value);
                if (val.value === '23:00' || val.value === '23:30') {
                  setEnd('23:59');
                } else if (end.split(':')[0] < val.value.split(':')[0]) {
                  setEnd(parseInt(val.value.split(':')[0]) + 1 + ':00');
                }
              }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: 'green',
                  borderRadius: '10px',
                  width: '100%',
                }),
              }}
            />
          </Col>
          <Col xs="auto" lg="auto" style={{ display: 'flex' }}>
            <ToLabel>TO</ToLabel>
            <Select
              options={getOptions()}
              placeholder="10:00 AM"
              className="hours-select"
              isDisabled={end === start ? true : false}
              value={{
                label: end,
                value: end,
              }}
              defaultValue={end}
              onChange={(val) => {
                handleWorkingTimes(val.value, item.values[1]);
                setEnd(val.value);
              }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: 'green',
                  borderRadius: '10px',
                  width: '100%',
                }),
              }}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
}
