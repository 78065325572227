import { useDispatch, useSelector } from 'react-redux';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import { silal_logo_green } from 'assets/index';
import { actionGetStoreInfoByID } from 'store/store/store_actions';
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalContent,
} from '@nextui-org/react';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { getCurrentStore } from 'core/hooks/use_selector';
import { useEffect, useState } from 'react';
import { SellerFilesRepository } from 'data/repositories/files_repository';
import { sellerAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { S3ApiRepository } from 'silal_app_base_react/src/data/repositories/s3_repository';
import ImageSelection from 'silal_app_base_react/src/components/image_selection';
import { Pip } from 'react-bootstrap-icons';
import { ModalFooter } from 'react-bootstrap';
import { actionGetStoreImages } from 'store/images/images_actions';
import { toast } from 'react-toastify';
import FileUploader from 'silal_app_base_react/src/components/file_uploader';

const AddImagesModal = () => {
  const dispatch = useDispatch();
  const currentStore = useSelector(getCurrentStore);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [image_modal, setImageModal] = useState(false);
  const [type, setType] = useState('');
  const s3Repository = S3ApiRepository.getInstance(
    sellerAxiosInstance,
    currentStore.id,
  ); // TODO move to a global context

  useEffect(() => {
    if (
      !currentStore.logo_img ||
      !currentStore.cover_image ||
      !currentStore.business_owner_image ||
      !currentStore.business_bank_statement
    ) {
      onOpen();
    }
  }, []);

  async function privateImagesUploadAndSet(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    const file = e?.target?.files ? e.target.files[0] : null;
    if (file) {
      const successfulImages = await toast.promise(
        s3Repository.addImages({
          images: [{ file }],
          is_public: false,
        }),
        {
          pending: 'Uploading image...',
          success: '',
          error: 'Error uploading image',
        },
      );
      var img;

      if (type === 'owner_id') {
        img = await SellerFilesRepository.setImageAsOwnerId({
          image_id: successfulImages[0]?.data?.data?.file?.id,
          store_id: currentStore.id,
        });
      } else if (type === 'bank_statement') {
        img = await SellerFilesRepository.setImageAsBusinessBankStatement({
          image_id: successfulImages[0]?.data?.data?.file?.id,
          store_id: currentStore.id,
        });
      } else if (type === 'tax_certification') {
        img = await SellerFilesRepository.setImageAsTaxCertificate({
          image_id: successfulImages[0]?.data?.data?.file?.id,
          store_id: currentStore.id,
        });
      } else if (type === 'das_certification') {
        img = await SellerFilesRepository.setImageAsDASCertificate({
          image_id: successfulImages[0]?.data?.data?.file?.id,
          store_id: currentStore.id,
        });
      }
      if (img) {
        dispatch(actionGetStoreInfoByID());
      }
    }
  }

  return (
    <>
      <Button onPress={onOpen} color="success" variant="flat">
        <Pip size={24} />
        Edit Store Documents
      </Button>
      <ImageSelection
        showModal={image_modal}
        minNumberOfImages={1}
        maxNumberOfImages={2}
        is_private={
          type === 'owner_id' ||
          type === 'bank_statement' ||
          type === 'tax_certification' ||
          type === 'das_certification'
        }
        s3Repository={s3Repository}
        filesRepository={SellerFilesRepository}
        updateSelectedImages={async (images) => {
          const image = images[0];
          let img: boolean = false;
          if (type === 'logo_img') {
            img = await SellerFilesRepository.setImageAsLogo({
              image_id: image.id,
              store_id: currentStore.id,
            });
          } else if (type === 'cover_image') {
            img = await SellerFilesRepository.setImageAsStoreCover({
              image_id: image.id,
              store_id: currentStore.id,
            });
          }
          if (img) {
            dispatch(actionGetStoreInfoByID());
          }
        }}
        setImageModal={setImageModal}
        actionGetStoreImages={actionGetStoreImages}
      />

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={() => {
          setImageModal(false);
        }}
        isDismissable={false}
        size="4xl"
        closeButton
        aria-labelledby="modal-title"
      >
        <ModalContent>
          {(onClose) => (
            <Wrapper>
              <ModalHeader className="flex flex-col justify-center align-middle">
                <img
                  className="d-flex justify-content-center mx-auto mb-3"
                  src={silal_logo_green}
                  width={100}
                  height={100}
                  alt="logo"
                />
                <p
                  id="modal-title"
                  className="d-flex justify-content-center mx-auto"
                >
                  Welcome to Silal!
                </p>
              </ModalHeader>
              <ModalBody>
                <p className="text-center">
                  We’ve noticed you’re missing some required information, please
                  add them before continuing as they are mandatory for using the
                  Silal platform.
                </p>

                <div className="row main-row">
                  {/* Logo Section */}
                  <div className="col-md-5">
                    <p>Business logo</p>
                    <div
                      className="row align-items-center flex-item px-2 py-2"
                      style={{
                        backgroundColor: currentStore?.logo_img
                          ? 'rgba(5, 174, 75, 0.2)'
                          : '',
                        border: currentStore?.logo_img
                          ? `1px solid ${Colors.primary}`
                          : '',
                      }}
                    >
                      <div className="col-md-9 border-end">
                        <p>
                          Please upload a high-quality picture of your logo.
                        </p>
                      </div>
                      <div
                        className="col-md-3 d-flex flex-column align-items-center"
                        onClick={() => {
                          setType('logo_img');
                          setImageModal(true);
                        }}
                      >
                        {currentStore?.logo_img ? (
                          <div className="image-container">
                            <ShowImage
                              image={currentStore.logo_img}
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        ) : (
                          <div className="circle" />
                        )}
                        <p>Upload</p>
                      </div>
                    </div>
                  </div>

                  {/* Cover Image Section */}
                  <div className="col-md-5">
                    <p>Business cover logo</p>
                    <div
                      className="row align-items-center flex-item px-2 py-2"
                      style={{
                        backgroundColor: currentStore?.cover_image
                          ? 'rgba(5, 174, 75, 0.2)'
                          : '',
                        border: currentStore?.cover_image
                          ? `1px solid ${Colors.primary}`
                          : '',
                      }}
                    >
                      <div className="col-md-9 border-end">
                        <p>Upload a high-quality rectangular cover image.</p>
                      </div>
                      <div
                        className="col-md-3 d-flex flex-column align-items-center"
                        onClick={() => {
                          setType('cover_image');
                          setImageModal(true);
                        }}
                      >
                        {currentStore?.cover_image ? (
                          <div className="image-container">
                            <img
                              src={currentStore.cover_image.public_s3_url}
                              alt="cover"
                            />
                          </div>
                        ) : (
                          <div className="circle" />
                        )}
                        <p>Upload</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Additional sections for Owner ID, Bank Statement, Tax Certificate, and DAS */}
                {/* Repeat the same structure for each section */}

                <div className="row main-row">
                  {/* Owner ID Section */}
                  <div className="col-md-5">
                    <p>Owner ID</p>
                    <div
                      className="row align-items-center flex-item px-2 py-2"
                      style={{
                        backgroundColor: currentStore?.owner_id
                          ? 'rgba(5, 174, 75, 0.2)'
                          : '',
                        border: currentStore?.owner_id
                          ? `1px solid ${Colors.primary}`
                          : '',
                      }}
                    >
                      <div className="col-md-9 border-end">
                        <p>
                          Please upload a high-quality picture of your owner ID.
                        </p>
                      </div>
                      <div
                        className="col-md-3 d-flex flex-column align-items-center"
                        onClick={() => {
                          setType('owner_id');
                          // setImageModal(true);
                        }}
                      >
                        <FileUploader
                          customFileHandler={async (
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            await privateImagesUploadAndSet(e);
                          }}
                          name={'owner_id'}
                        >
                          {currentStore.owner_id ? (
                            <div className="image-container">
                              <ShowImage
                                image={currentStore.business_owner_image}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          ) : (
                            <div className="circle" />
                          )}
                          <p>Upload</p>
                        </FileUploader>
                        {/* <input
                          type="file"
                          onChange={async (e) => {
                            await privateImagesUploadAndSet(e);
                          }}
                        />
                        {currentStore?.owner_id ? (
                          <div className="image-container">
                            <ShowImage
                              image={currentStore.business_owner_image}
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        ) : (
                          <div className="circle" />
                        )}
                        <p>Upload</p> */}
                      </div>
                    </div>
                  </div>

                  {/* Bank Statement Section */}
                  <div className="col-md-5">
                    <p>Business bank statement</p>
                    <div
                      className="row align-items-center flex-item px-2 py-2"
                      style={{
                        backgroundColor: currentStore?.business_bank_statement
                          ? 'rgba(5, 174, 75, 0.2)'
                          : '',
                        border: currentStore?.business_bank_statement
                          ? `1px solid ${Colors.primary}`
                          : '',
                      }}
                    >
                      <div className="col-md-9 border-end">
                        <p>
                          Please upload a high-quality picture of your business
                          bank statement.
                        </p>
                      </div>
                      <div
                        className="col-md-3 d-flex flex-column align-items-center"
                        onClick={() => {
                          setType('bank_statement');
                        }}
                      >
                        <FileUploader
                          customFileHandler={async (
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            await privateImagesUploadAndSet(e);
                          }}
                          name={'bank_statement'}
                        >
                          {currentStore.business_bank_statement ? (
                            <div className="image-container">
                              <ShowImage
                                image={currentStore.business_bank_statement}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          ) : (
                            <div className="circle" />
                          )}
                          <p>Upload</p>
                        </FileUploader>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row main-row">
                  {/* Tax Certificate Section */}
                  <div className="col-md-5">
                    <p>Tax certificate</p>
                    <div
                      className="row align-items-center flex-item px-2 py-2"
                      style={{
                        backgroundColor: currentStore?.business_tax_certificate
                          ? 'rgba(5, 174, 75, 0.2)'
                          : '',
                        border: currentStore?.business_tax_certificate
                          ? `1px solid ${Colors.primary}`
                          : '',
                      }}
                    >
                      <div className="col-md-9 border-end">
                        <p>
                          Please upload a high-quality picture of your tax
                          certificate.
                        </p>
                      </div>
                      <div
                        className="col-md-3 d-flex flex-column align-items-center"
                        onClick={() => {
                          setType('tax_certification');
                        }}
                      >
                        <FileUploader
                          customFileHandler={async (
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            await privateImagesUploadAndSet(e);
                          }}
                          name={'tax_certification'}
                        >
                          {currentStore.business_tax_certificate ? (
                            <div className="image-container">
                              <ShowImage
                                image={currentStore.business_tax_certificate}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          ) : (
                            <div className="circle" />
                          )}
                          <p>Upload</p>
                        </FileUploader>
                      </div>
                    </div>
                  </div>

                  {/* DAS Certificate Section */}
                  <div className="col-md-5">
                    <p>DAS certificate</p>
                    <div
                      className="row align-items-center flex-item px-2 py-2"
                      style={{
                        backgroundColor: currentStore?.business_das_certificate
                          ? 'rgba(5, 174, 75, 0.2)'
                          : '',
                        border: currentStore?.business_das_certificate
                          ? `1px solid ${Colors.primary}`
                          : '',
                      }}
                    >
                      <div className="col-md-9 border-end">
                        <p>
                          Please upload a high-quality picture of your DAS
                          certificate.
                        </p>
                      </div>
                      <div
                        className="col-md-3 d-flex flex-column align-items-center"
                        onClick={() => {
                          setType('das_certification');
                        }}
                      >
                        <FileUploader
                          customFileHandler={async (
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            await privateImagesUploadAndSet(e);
                          }}
                          name={'das_certification'}
                        >
                          {currentStore.business_das_certificate ? (
                            <div className="image-container">
                              <ShowImage
                                image={currentStore.business_das_certificate}
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          ) : (
                            <div className="circle" />
                          )}
                          <p>Upload</p>
                        </FileUploader>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="m-2 mb-4  flex justify-center">
                <Button
                  color="success"
                  onPress={() => {
                    onClose();
                  }}
                >
                  Done
                </Button>
              </ModalFooter>
            </Wrapper>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

const Wrapper = styled.div`
  .circle {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background: rgba(76, 104, 112, 0.5);
    background-position: center;
    background-repeat: no-repeat;
  }

  .image-container {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    img {
      max-width: 100%;
      height: 100%;
    }
  }

  .main-row {
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }
  .flex-item {
    background: #f1f2f3;
    border-radius: 5px;
  }
  .addImagesModal {
    max-width: 70%;
  }
  .modal-header {
    padding: 30px 50px 28px 50px;
    .modal-heading {
      font-family: ${Fonts.secondary};
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #002733;
    }
    .btn-close {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  .modal-body {
    padding: 0 50px 40px 50px;
    p {
      font-family: ${Fonts.secondary};
      font-size: 15px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(76, 104, 112, 0.5);
      padding: 0.5rem 0;
      margin: 0;
    }

    span {
      color: red;
    }

    .heading {
      margin: 0;
      padding: 0;
      font-family: ${Fonts.secondary};
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #4c7061;
      margin: 0.5rem 0;
    }

    .footer {
      button {
        width: 100%;
        color: white;
        background-color: #05ae4b;
      }
    }
  }
`;

export default AddImagesModal;
