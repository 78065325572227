import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { Switch } from 'styles/style';
import { getCurrentStore } from 'core/hooks/use_selector';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@nextui-org/react';

type ProfileBannerProps = {
  edit: boolean;
  bgImg: string;
  avatar: string;
};

const ProfileBanner = ({ edit, bgImg, avatar }: ProfileBannerProps) => {
  const currentStore = useSelector(getCurrentStore);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (!imageError) {
      const img = new Image();
      img.src = currentStore.cover_image?.public_s3_url || bgImg;
      img.onload = () => {
        // Image loaded successfully, no error
      };
      img.onerror = () => {
        // Error loading the image
        setImageError(true);
      };
    }
  }, [imageError, currentStore.cover_image, bgImg]);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const imageUrl = imageError
    ? bgImg
    : currentStore.cover_image?.public_s3_url || bgImg;

  return (
    <ProfileBannerStyled
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer',
      }}
      className={edit ? 'edit' : ''}
      onClick={onOpen}
    >
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="3xl"
        placement="center"
      >
        <ModalContent>
          <>
            <ModalBody className="text-center">
              <img
                src={imageUrl}
                alt="Full View"
                style={{
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  margin: '10px',
                  borderRadius: '10px',
                }}
              />
            </ModalBody>
          </>
        </ModalContent>
      </Modal>
      {edit ? (
        <>
          <div className="edit-avatar center">
            {/* <div // TODO renable if want to allow user to update all docs
              style={{
                backgroundColor: 'black',
                opacity: '0.5',
              }}
            >
              <img src={addPhotoIcon_path} className="add-icon" />
            </div> */}

            {currentStore.logo_img ? (
              <ShowImage
                image={currentStore.logo_img}
                classNames={''}
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <img src={avatar} alt="avatar" />
            )}
          </div>
          {/* <div className="edit-button-container">
            <div
              style={{
                backgroundColor: 'black',
                opacity: '0.3',
                borderRadius: '20%',
              }}
            >
              <img src={bannereditIcon_path} />
            </div>
            <input type="file" onChange={(e) => handleFileChange(e, 'cover')} />
          </div> */}
        </>
      ) : (
        <>
          {currentStore.logo_img ? (
            <ShowImage
              image={currentStore.logo_img}
              classNames={'avatar'}
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
              }}
            />
          ) : (
            <img src={avatar} alt="avatar" className="avatar" />
          )}
        </>
      )}
    </ProfileBannerStyled>
  );
};

type ToggleBtnProps = {
  name: string;
  onchange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  label: string;
};

const ToggleBtn = ({ name, onchange, checked, label }: ToggleBtnProps) => {
  return (
    <div className="d-flex align-items-center">
      <ToggleBtnStyled>
        <input
          type="checkbox"
          name={name}
          onChange={onchange}
          checked={checked}
        />
        <span className="slider" />
      </ToggleBtnStyled>
      <ToggleLabel>{label}</ToggleLabel>
    </div>
  );
};

const ProfileBannerStyled = styled.div`
  position: relative;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &.edit {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .edit-button-container {
    position: relative;
    z-index: 10;
    width: max-content;
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .avatar {
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    background-color: #ffffff;
    object-fit: cover;
  }
  .edit-avatar {
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    background-color: #ffffff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
    }
    .add-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      transform: translate(-50%, -50%);
      pointer-events: none;
      cursor: pointer;
    }
  }
`;

const ToggleBtnStyled = styled(Switch)`
  z-index: 1;
  width: 51px;
  height: 31px;
  .slider {
    background-color: #78788029;
    &::before {
      bottom: 1.5px;
    }
  }
  input:checked + .slider {
    background-color: #34c759;
  }
  input:checked + .slider:before {
    transform: translateX(19px);
  }
`;

const ToggleLabel = styled.label`
  width: 46px;
  font-family: ${Fonts.secondary};
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #4c6870;
  margin-left: 12px;
`;

export { ProfileBanner, ToggleBtn };
