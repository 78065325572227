import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';

import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import CountrySelectForm from 'components/select_country_form';
import Backbutton from 'pages/auth/components/back_button';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button, Input } from '@nextui-org/react';
import { APP_LINKS } from 'silal_app_base_react/src/config/constants';
import { Helmet } from 'react-helmet-async';

function PhoneSignupScreen() {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState('');
  const [error, setError] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({
    label: `IL +972`,
    value: '+972',
    default: true,
  });

  const animate1 = {
    x: [-100, 0],
    opacity: [0, 1],
    transition: { duration: 0.5 },
  };

  useEffect(() => {}, []);

  const setPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue(e.target.value);
  };

  const sendToOtp = async () => {
    if (!formValue) {
      setError('ⓘ Please enter a phone number');
      return;
    }
    if (!FormValidators.isPhoneNumber(formValue)) {
      setError('ⓘ Please enter a valid phone number 05XXXXXXXX');
      return;
    }
    await AuthenticationRepository.signupSendPhoneOTP(
      selectedCountry.value + formValue,
    ).then((res) => {
      if (!res) return;
      navigate('/phone-otp-verification', {
        state: {
          phoneNo: selectedCountry.value + formValue,
          previousPage: 'signup',
          expiration_date: res.expiration_date,
        },
      });
    });
  };

  return (
    <BackgroundContainer>
      <Helmet>
        <title>Setup Phone | Silal Merchant</title>
      </Helmet>
      <Wrapper>
        <div className="top-row container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              baselineShift: 'center',
            }}
          >
            <div className="go-back-button">
              <Backbutton primaryIcon={true} />
            </div>
            <div className="title">
              <h1>Sign Up</h1>
            </div>
            <div className="go-back-button" style={{ visibility: 'hidden' }}>
              <Backbutton primaryIcon={true} />
            </div>
          </div>
          <div className="text-center">
            <div className="form">
              <motion.div className="form-control-mobile" animate={animate1}>
                <div
                  className="row"
                  style={{
                    border: 'none',
                    alignItems: 'center',
                  }}
                >
                  <div className="col-12 col-lg-5 mb-2 pr-0">
                    <CountrySelectForm
                      value={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                    />
                  </div>
                  <div className="col-12 col-lg-7 mb-2">
                    <Input
                      isRequired
                      value={formValue}
                      type="text"
                      label="Phone Number"
                      placeholder="0541234567"
                      autoComplete="tel"
                      isInvalid={!!error}
                      color={error ? 'danger' : 'success'}
                      variant="bordered"
                      errorMessage={error}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only numbers
                        if (!/^\d*$/.test(value)) return;
                        if (value.length > 10) return;
                        setFormValue(value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: 'grey',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {formValue.length + '/10'}
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    onClick={sendToOtp}
                    color={'success'}
                    className="w-full mt-5 mb-2"
                  >
                    Next
                  </Button>
                </div>
              </motion.div>
            </div>
            <div>
              <h6>
                By signing up, you agree to the{' '}
                <Link
                  to={APP_LINKS.policies.terms_of_service_and_privacy_policy}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      color: Colors.greenMain,
                    }}
                  >
                    Terms of Service & Privacy Policy
                  </span>
                </Link>{' '}
                and the latest version of the
                <span
                  style={{
                    fontWeight: 500,
                    color: Colors.greenAlternate,
                  }}
                >
                  {' '}
                  Merchants contract{' '}
                </span>
              </h6>
            </div>
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default PhoneSignupScreen;

const Wrapper = styled.div`
  background: #fff;
  max-width: 430px;
  height: fit-content;
  padding: 0px 20px 20px 20px;
  border-radius: 30px;
  font-family: ${Fonts.secondary}, sans-serif;
  position: relative;
  margin: 20px;
  .top-row {
    .title {
      padding: 25px 0;
      h1 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form-control-mobile {
        margin-top: 20px;
        label {
          font-size: 15px;
          font-weight: 700;
          color: #4c6870;
        }
        .row {
          border: 1px solid #ededed;
          border-radius: 10px;
          margin-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .col1 {
            max-width: 130px;
            width: 100%;
            .value-container {
              border-right: none;
              border-radius: 5px 0px 0px 5px;
            }
          }
          .col2 {
            input {
              border-radius: 0px 5px 5px 0px;
            }
          }
        }
      }
      /* email form design  */
      .form-control-email {
        .row {
          margin-top: 20px;
          label {
            font-size: 15px;
            font-weight: 700;
            color: #4c6870;
          }
        }
      }
    }
  }

  .next {
    margin-top: 200px;
  }
  .methodTabButton {
    background: #05ae4b;
    color: #ffffff;
    border: none;
    margin: 5px;
    width: 60%;
    font-size: 1.2rem;
  }
  .submitButton {
    background: #05ae4b;
    color: #ffffff;
    border: none;
    margin: 5px;
    width: 100%;
    margin-top: 50px;
  }
  h6 {
    margin: 5px;
    font-weight: 500;
    font-size: 15px;
    color: #4c7061;
  }
  .colored {
    font-weight: 500;
    color: #05ae4b;
  }
`;
