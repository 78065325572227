import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalStyle from 'styles/global_styled';
import Form1 from './components/form1';
import Form2 from './components/form2';
import Form3 from './components/form3';
import FormHeader from './components/form_header';
import { StoreSignUpForm } from 'data/types/store';
import { CurrentContries } from 'core/constants/constants';
import StoreRepository from 'data/repositories/store_repository';
import { usePersistState } from 'silal_app_base_react/src/hooks/usePersistState';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { silal_items_backhground_placeholder } from 'assets/index';
import { businessTaxTypeMap } from 'silal_app_base_react/src/data/types/stores';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

function Form() {
  const [step, setCurrentStep] = useState<number>(1);
  const [modal, setModalState] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = usePersistState<StoreSignUpForm>(
    {
      name_en: '',
      name_ar: '',
      business_owner_full_name: '',
      business_phone: '',
      business_email: '',
      business_website_url: '',
      business_social_media_url: '',
      tax_id: '',
      address: {
        address_line_1: '',
        address_line_2: '',
        city_id: '',
        town_id: '',
        region_id: '',
        notes: '',
        lat: '',
        lng: '',
      },
      slogan: '',
      main_category_id: '',
      bio: '',
      working_time_sat_start: '08:00',
      working_time_sun_start: '08:00',
      working_time_mon_start: '08:00',
      working_time_tue_start: '08:00',
      working_time_wed_start: '08:00',
      working_time_thu_start: '08:00',
      working_time_fri_start: '08:00',

      working_time_sat_end: '08:00',
      working_time_sun_end: '08:00',
      working_time_mon_end: '08:00',
      working_time_tue_end: '08:00',
      working_time_wed_end: '08:00',
      working_time_thu_end: '08:00',
      working_time_fri_end: '08:00',

      accepts_mobile_payments: false,
      accepts_cash_payments: false,
      accepts_credit_card_payments: false,
      accepts_cheques_payments: false,

      is_home_business: false,

      tax_type: '',
      bank_number: '',
      bank_branch_number: '',
      bank_account_number: '',

      workingTimeStart: {},
      workingTimeEnd: {},
    },
    'signup_form',
  );
  //TODO this wasnt used
  // const errValues = {
  //   businessNameEn: '',
  //   businessNameAr: '',
  //   businessOwnerName: '',
  //   countryCode: '',
  //   businessPhone: '',
  //   businessEmail: '',
  //   businessSocialMediaProfile: '',
  //   businessSocialOtherProfile: '',
  //   tax_id: '',
  //   address: {
  //     address1: '',
  //     address2: '',
  //     cityId: '',
  //     townId: '',
  //     regionId: '',
  //     notes: '',
  //     lat: '',
  //     lng: '',
  //   },
  //   slogan: '',
  //   category_id: '',
  //   bio: '',
  //   working_time_sat_start: '',
  //   working_time_sun_start: '',
  //   working_time_mon_start: '',
  //   working_time_tue_start: '',
  //   working_time_wed_start: '',
  //   working_time_thu_start: '',
  //   working_time_fri_start: '',

  //   working_time_sat_end: '',
  //   working_time_sun_end: '',
  //   working_time_mon_end: '',
  //   working_time_tue_end: '',
  //   working_time_wed_end: '',
  //   working_time_thu_end: '',
  //   working_time_fri_end: '',

  //   accepts_mobile_payments: '',
  //   accepts_cash_payments: '',
  //   accepts_credit_card_payments: '',
  //   accepts_cheques_payments: '',
  // };

  function extractLastNineNumbers(phoneNumber: string) {
    const localNumber = phoneNumber.split(' ')[1];
    const digitsOnly = localNumber.replace(/\D/g, '');
    return digitsOnly.length === 8 ? '0' + digitsOnly : digitsOnly;
  }

  useEffect(() => {
    const store = location.state?.store;
    if (store) {
      setTimeout(() => {
        setFormData({
          name_en: store.name_en,
          name_ar: store.name_ar,
          business_owner_full_name: store.business_owner_full_name,
          business_phone: extractLastNineNumbers(store.business_phone),
          business_email: store.business_email,
          business_website_url: store.business_social_media_url || '',
          business_social_media_url: store.business_website_url || '',
          tax_id: store.tax_id,
          address: {
            address_line_1: store.address[0].address_line_1 || '',
            address_line_2: store.address[0].address_line_2 || '',
            city_id: store.address[0].city?.id || '',
            town_id: store.address[0].town?.id || '',
            region_id: store.region || '',
            notes: '',
            lat: store.address[0].lat,
            lng: store.address[0].lng,
          },
          slogan: store.slogan || '',
          main_category_id: store.main_category_id,
          bio: store.bio,

          working_time_sun_start: store.working_time_sun_start,
          working_time_mon_start: store.working_time_mon_start,
          working_time_tue_start: store.working_time_tue_start,
          working_time_wed_start: store.working_time_wed_start,
          working_time_thu_start: store.working_time_thu_start,
          working_time_fri_start: store.working_time_fri_start,
          working_time_sat_start: store.working_time_sat_start,

          working_time_sun_end: store.working_time_sun_end,
          working_time_mon_end: store.working_time_mon_end,
          working_time_tue_end: store.working_time_tue_end,
          working_time_wed_end: store.working_time_wed_end,
          working_time_thu_end: store.working_time_thu_end,
          working_time_fri_end: store.working_time_fri_end,
          working_time_sat_end: store.working_time_sat_end,

          accepts_mobile_payments: store.accepts_mobile_payments,
          accepts_cash_payments: store.accepts_cash_payments,
          accepts_credit_card_payments: store.accepts_credit_card_payments,
          accepts_cheques_payments: store.accepts_cheques_payments,
          is_home_business: store.is_home_business,
          workingTimeStart: {
            working_time_sat_start: store.working_time_sat_start,
            working_time_sun_start: store.working_time_sun_start,
            working_time_mon_start: store.working_time_mon_start,
            working_time_tue_start: store.working_time_tue_start,
            working_time_wed_start: store.working_time_wed_start,
            working_time_thu_start: store.working_time_thu_start,
            working_time_fri_start: store.working_time_fri_start,
          },
          workingTimeEnd: {
            working_time_sat_start: store.working_time_sat_start,
            working_time_sun_start: store.working_time_sun_start,
            working_time_mon_start: store.working_time_mon_start,
            working_time_tue_start: store.working_time_tue_start,
            working_time_wed_start: store.working_time_wed_start,
            working_time_thu_start: store.working_time_thu_start,
            working_time_fri_start: store.working_time_fri_start,
          },
          tax_type: businessTaxTypeMap[store.tax_type],
          bank_number: store.bank_number,
          bank_branch_number: store.bank_branch_number,
          bank_account_number: store.bank_account_number,
        });
      }, 500);
    }
  }, [location]);

  // utility functions
  const handleUserInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const handleWorkingTimes = (key: string, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleAddressInputs = (e: any) => {
    // TODO fix any
    const name = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      address: { ...formData.address, [name]: value },
    });
  };

  const setSelectedCountry = () => {
    setFormData({ ...formData });
  };

  const checkWorkingHoursValidation = () => {
    if (
      formData.working_time_sat_start &&
      formData.working_time_sun_start &&
      formData.working_time_mon_start &&
      formData.working_time_tue_start &&
      formData.working_time_wed_start &&
      formData.working_time_thu_start &&
      formData.working_time_fri_start &&
      formData.working_time_sat_start &&
      formData.working_time_sun_end &&
      formData.working_time_mon_end &&
      formData.working_time_tue_end &&
      formData.working_time_wed_end &&
      formData.working_time_thu_end &&
      formData.working_time_fri_end &&
      formData.working_time_sat_end
    ) {
      return true;
    }
    return false;
  };

  const createStore = async () => {
    if (!checkWorkingHoursValidation()) {
      toast.warn('Please select all working hours for each day!');
      return;
    }
    const data = {
      name_en: formData.name_en,
      name_ar: formData.name_ar,
      bio: formData.bio,
      slogan: formData.slogan,
      tax_id: formData.tax_id,
      business_owner_full_name: formData.business_owner_full_name,
      business_phone: CurrentContries[0].value + formData.business_phone,
      business_email: formData.business_email,
      business_website_url: formData.business_website_url,
      business_social_media_url: formData.business_social_media_url,
      main_category_id: formData.main_category_id,
      working_time_sun_start: formData.working_time_sun_start,
      working_time_mon_start: formData.working_time_mon_start,
      working_time_tue_start: formData.working_time_tue_start,
      working_time_wed_start: formData.working_time_wed_start,
      working_time_thu_start: formData.working_time_thu_start,
      working_time_fri_start: formData.working_time_fri_start,
      working_time_sat_start: formData.working_time_sat_start,

      working_time_sun_end: formData.working_time_sun_end,
      working_time_mon_end: formData.working_time_mon_end,
      working_time_tue_end: formData.working_time_tue_end,
      working_time_wed_end: formData.working_time_wed_end,
      working_time_thu_end: formData.working_time_thu_end,
      working_time_fri_end: formData.working_time_fri_end,
      working_time_sat_end: formData.working_time_sat_end,

      address: {
        address_line_1: formData.address.address_line_1,
        address_line_2: formData.address.address_line_2,
        notes: formData.address.notes,
        town_id: formData.address.town_id,
        lat: formData.address.lat,
        lng: formData.address.lng,
      },

      accepts_mobile_payments: formData.accepts_mobile_payments,
      accepts_cash_payments: formData.accepts_cash_payments,
      accepts_credit_card_payments: formData.accepts_credit_card_payments,
      accepts_cheques_payments: formData.accepts_cheques_payments,

      is_home_business: formData.is_home_business,

      tax_type: formData.tax_type,
      bank_number: formData.bank_number,
      bank_branch_number: formData.bank_branch_number,
      bank_account_number: formData.bank_account_number,
    };
    if (location.state?.store) {
      submitStore({
        ...data,
        id_of_store_to_delete: location.state?.store.id,
      });
    } else {
      submitStore(data);
    }
  };

  const submitStore = async (data: StoreSignUpForm) => {
    await StoreRepository.createStore(data).then((res) => {
      if (!res) return;
      navigate('/submit-questionaire-form', {
        replace: true,
      });
    });
  };

  return (
    <>
      <Helmet>
        <title>Register Business | Silal Merchant</title>
      </Helmet>
      <div
        className="d-flex justify-content-center"
        style={{
          background: `url(${silal_items_backhground_placeholder}) no-repeat center center fixed`,
          backgroundSize: 'cover',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className="container m-10"
          style={{
            width: '75%',
          }}
        >
          {modal && (
            <PopupWithConfirmation
              open={modal}
              onButtonOneClick={() => setModalState(false)}
              onButtonTwoClick={async () => {
                setModalState(false);
                navigate('/choose-business');
              }}
              headerTitle={'Are you sure you want to leave?'}
              bodyText={'You will lose all the data you have entered so far. '}
              buttonTwoText={'Yes, I am sure'}
              withTimerOfNSeconds={null}
            />
          )}
          <GlobalStyle />
          <div
            className="d-flex justify-content-center bg-white"
            style={{
              borderRadius: '20px',
              padding: '10px',
            }}
          >
            <div className="row d-flex flex-column ">
              <FormHeader currentStep={step} setModalState={setModalState} />
              {step === 1 ? (
                <Form1
                  setCurrentStep={setCurrentStep}
                  step={step}
                  setModalState={setModalState}
                  formData={formData}
                  handleUserInput={handleUserInput}
                  setSelectedCountry={setSelectedCountry}
                />
              ) : (
                ' '
              )}
              {step === 2 ? (
                <Form2
                  setCurrentStep={setCurrentStep}
                  step={step}
                  formData={formData}
                  setFormData={setFormData}
                  handleUserInput={handleAddressInputs}
                />
              ) : (
                ' '
              )}
              {step === 3 ? (
                <Form3
                  formData={formData}
                  setFormData={setFormData}
                  setCurrentStep={setCurrentStep}
                  step={step}
                  handleUserInput={handleUserInput}
                  handleWorkingTimes={handleWorkingTimes}
                  createStore={createStore}
                />
              ) : (
                ' '
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
