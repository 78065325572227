import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';
import { actionUpdateStore } from 'store/store/store_actions';
import {
  storeProfilePlaceholder_path,
  storeCoverPlaceholder_path,
} from 'assets/index';
import GlobalStyle from 'styles/global_styled';
import { Heading, HeadingStyled, Input, Label, Textarea } from 'styles/style';
// import BusinessAddresses from './archive/flow/update_business_address_flow';
import { ProfileBanner } from './components';
import Nav from './components/profile_navbar';
import Error from 'silal_app_base_react/src/components/error';
import { getCurrentStore, getCurrentUserState } from 'core/hooks/use_selector';
import { toast } from 'react-toastify';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { getOpeningHours } from 'silal_app_base_react/src/utils/functions/store_functions';
import {
  formatPrice,
  formatStoreBankDetails,
} from 'silal_app_base_react/src/utils/functions/formatting_functions';

import { businessTaxTypeMap } from 'silal_app_base_react/src/data/types/stores';
import { Icon } from '@iconify/react';
import AddImagesModal from './modals/add_images_modal';
import { Button } from '@nextui-org/react';
import {
  RiBox1Line,
  RiClockwise2Line,
  RiHourglassLine,
  RiInformation2Line,
  RiMapPin2Line,
  RiMoneyDollarCircleLine,
  RiShip2Line,
  RiTimer2Line,
} from 'react-icons/ri';
import { LuNfc } from 'react-icons/lu';
import { FaRegCreditCard } from 'react-icons/fa';
import { BsCashCoin } from 'react-icons/bs';
import { LiaMoneyCheckAltSolid } from 'react-icons/lia';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const currentStore = useSelector(getCurrentStore);
  const { user } = useSelector(getCurrentUserState);
  const [store, setStore] = useState(currentStore);
  const [editState, setEditState] = useState(false);
  const [errors, setErrors] = useState<any>();

  useEffect(() => {
    if (currentStore?.id) {
      setStore(currentStore);
    }
  }, [currentStore]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'bio' && store.bio.length > 249) {
      return;
    }
    setStore({ ...store, [name]: value });
  };

  const handlePaymentMethods = (value: boolean, type: string) => {
    setStore({ ...store, [type]: value });
  };

  const handleValidations = () => {
    const errorsFields = {
      business_website_url: '',
      business_social_media_url: '',
      bio: '',
      slogan: '',
    };
    let error = false;
    Object.keys(store).map(function (keyName) {
      const value = store[keyName as keyof typeof store];
      if (keyName === 'business_website_url') {
        if ((value as string)?.trim().length > 0) {
          const valid = FormValidators.isURL(value);
          if (!valid) {
            errorsFields.business_website_url =
              'ⓘ Business Social Media Profile should be valid URL.';
            error = true;
          }
        }
      }

      if (keyName === 'business_social_media_url') {
        if ((value as string)?.trim().length > 0) {
          const valid2 = FormValidators.isURL(value);
          if (!valid2) {
            errorsFields.business_social_media_url =
              'ⓘ Business Social Media Other Profile should be valid URL.';
            error = true;
          }
        }
      }
      return null;
    });
    setErrors(errorsFields);
    return error;
  };

  const submit = () => {
    if (handleValidations()) {
      toast.warn('Invalid Fields!');
      return;
    }
    if (
      !(
        store.accepts_cash_payments ||
        store.accepts_cheques_payments ||
        store.accepts_credit_card_payments ||
        store.accepts_mobile_payments
      )
    ) {
      toast.warn('Please select at least one payment method!');
      return;
    }
    const payload = {
      data: {
        ...store,
        tax_type:
          businessTaxTypeMap[store.tax_type as keyof typeof businessTaxTypeMap],
      },
      success: () => setEditState(false),
    };

    dispatch(actionUpdateStore(payload));
  };

  const handleCancelChange = () => {
    setStore(currentStore);
    setEditState(false);
  };

  /*
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, []);
*/
  return (
    <Wrapper>
      <GlobalStyle />
      <Nav />
      <div className="d-flex profile-container">
        <div className="w-100">
          <Row className="mb-2">
            <Row
              style={{
                borderRadius: '10px',
                margin: '5px',
                padding: '5px',
                backgroundColor: 'white',
                boxShadow:
                  'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px',
              }}
            >
              <Col lg={12} md={12}>
                <ProfileBanner
                  edit={editState}
                  bgImg={storeCoverPlaceholder_path}
                  avatar={storeProfilePlaceholder_path}
                />
              </Col>
              <Col lg={12} md={12}>
                <Row className="profile-edit">
                  <div className="edit pb-3 pt-3">
                    {editState ? (
                      <>
                        {errors?.name_en ? (
                          <Error text={errors?.name_en} />
                        ) : (
                          <Label
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Store Name (English)
                          </Label>
                        )}
                        <Input
                          name="name_en"
                          placeholder="Store Name"
                          value={store?.name_en}
                          style={{
                            border: errors?.name_en
                              ? '1px solid red'
                              : '1px solid #E5E5E5',
                          }}
                          onChange={handleChange}
                        />
                        {errors?.name_ar ? (
                          <Error text={errors?.name_ar} />
                        ) : (
                          <Label
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Store Name (Arabic)
                          </Label>
                        )}
                        <Input
                          name="name_ar"
                          placeholder="Store Name"
                          value={store?.name_ar}
                          style={{
                            border: errors?.name_ar
                              ? '1px solid red'
                              : '1px solid #E5E5E5',
                          }}
                          onChange={handleChange}
                        />
                        {errors?.slogan ? (
                          <Error text={errors?.slogan} />
                        ) : (
                          <Label
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Store slogan
                          </Label>
                        )}
                        <Input
                          name="slogan"
                          placeholder="Best food in town"
                          value={store?.slogan}
                          style={{
                            border: errors?.slogan
                              ? '1px solid red'
                              : '1px solid #E5E5E5',
                          }}
                          onChange={handleChange}
                        />

                        {errors?.slogan ? (
                          <Error text={errors?.slogan} />
                        ) : (
                          <Label
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Store Free Shipping Threshold
                          </Label>
                        )}
                        <Input
                          name="free_shipping_threshold"
                          placeholder="0.00"
                          value={store?.free_shipping_threshold}
                          style={{
                            border: errors?.free_shipping_threshold
                              ? '1px solid red'
                              : '1px solid #E5E5E5',
                          }}
                          onChange={handleChange}
                        />
                        {errors?.order_preparation_hours ? (
                          <Error text={errors?.order_preparation_hours} />
                        ) : (
                          <Label
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Order Preparation Hours{' '}
                            <span style={{ color: Colors.greenAlternate }}>
                              (Minimum time store needs to prepare items in
                              hours. This affects all items.)
                            </span>
                          </Label>
                        )}
                        <Input
                          name="order_preparation_hours"
                          placeholder="0.00"
                          value={store?.order_preparation_hours}
                          style={{
                            border: errors?.order_preparation_hours
                              ? '1px solid red'
                              : '1px solid #E5E5E5',
                          }}
                          onChange={handleChange}
                        />
                        {errors?.bio ? (
                          <Error text={errors?.bio} />
                        ) : (
                          <div className="between">
                            <Label
                              style={{
                                fontSize: '15px',
                              }}
                            >
                              Description
                            </Label>
                            <p
                              className={`charac-left ${
                                250 - store?.bio?.length < 10
                                  ? 'text-danger'
                                  : ''
                              }`}
                            >
                              {250 - store?.bio?.length} characters left
                            </p>
                          </div>
                        )}

                        <Textarea
                          name="bio"
                          placeholder="What’s a description that describes your business?"
                          value={store?.bio}
                          onChange={(e) => handleChange(e)}
                        />
                        {errors?.business_website_url ? (
                          <Error text={errors?.business_website_url} />
                        ) : (
                          <Label
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Business profile on social media / website - 1
                            (optional)
                          </Label>
                        )}
                        <Input
                          name="business_website_url"
                          placeholder="https://www.business_website.com"
                          value={store?.business_website_url}
                          style={{
                            border: errors?.business_website_url
                              ? '1px solid red'
                              : '1px solid #E5E5E5',
                          }}
                          onChange={handleChange}
                        />
                        {errors?.business_social_media_url ? (
                          <Error text={errors?.business_social_media_url} />
                        ) : (
                          <Label
                            style={{
                              fontSize: '15px',
                            }}
                          >
                            Business profile on social media / website - 2
                            (optional)
                          </Label>
                        )}

                        <Input
                          name="business_social_media_url"
                          placeholder="https://www.instagram.com/business_profile"
                          value={store?.business_social_media_url}
                          style={{
                            border: errors?.business_social_media_url
                              ? '1px solid red'
                              : '1px solid #E5E5E5',
                          }}
                          onChange={handleChange}
                        />
                        <Label
                          style={{
                            fontSize: '15px',
                          }}
                        >
                          Accepted payment methods{' '}
                          <strong className="red">*</strong>{' '}
                        </Label>
                        <div className=" row p-2">
                          <div className=" col-lg-5 border p-2 m-2">
                            <div className="one form-check">
                              <input
                                className="padd form-check-input"
                                type="checkbox"
                                checked={store.accepts_cash_payments}
                                onChange={(e) =>
                                  handlePaymentMethods(
                                    e.target.checked,
                                    'accepts_cash_payments',
                                  )
                                }
                                id="accepts_cash_payments"
                              />
                              <label className="form-check-label">
                                Cash payment
                              </label>
                            </div>
                          </div>

                          <div className=" col-lg-5 border p-2 m-2">
                            <div className="one form-check">
                              <input
                                className="padd form-check-input"
                                type="checkbox"
                                checked={store.accepts_credit_card_payments}
                                onChange={(e) =>
                                  handlePaymentMethods(
                                    e.target.checked,
                                    'accepts_credit_card_payments',
                                  )
                                }
                                id="accepts_credit_card_payments"
                              />
                              <label className="form-check-label">
                                Card payment
                              </label>
                            </div>
                          </div>

                          <div className=" col-lg-5 border p-2 m-2">
                            <div className="one form-check">
                              <input
                                className="padd form-check-input"
                                type="checkbox"
                                checked={store.accepts_mobile_payments}
                                onChange={(e) =>
                                  handlePaymentMethods(
                                    e.target.checked,
                                    'accepts_mobile_payments',
                                  )
                                }
                                id="accepts_mobile_payments"
                              />
                              <label className="form-check-label">
                                Apple Pay, Google Pay
                              </label>
                            </div>
                          </div>

                          <div className=" col-lg-5 border p-2 m-2">
                            <div className="one form-check">
                              <input
                                className="padd form-check-input"
                                type="checkbox"
                                checked={store.accepts_cheques_payments}
                                onChange={(e) =>
                                  handlePaymentMethods(
                                    e.target.checked,
                                    'accepts_cheques_payments',
                                  )
                                }
                                id="accepts_cheques_payments"
                              />
                              <label className="form-check-label">
                                Cheques
                              </label>
                            </div>
                          </div>
                        </div>
                        <Label
                          style={{
                            fontSize: '15px',
                          }}
                        >
                          Bank Account Info (Bank - Branch - Account){' '}
                          <strong className="red">*</strong>{' '}
                        </Label>
                        <div className="flex flex-row gap-3">
                          <Input
                            name="bank_number"
                            placeholder="XX"
                            type="number"
                            value={store?.bank_number}
                            style={{
                              border: errors?.bank_number
                                ? '1px solid red'
                                : '1px solid #E5E5E5',
                            }}
                            onChange={handleChange}
                          />{' '}
                          <Input
                            name="bank_branch_number"
                            placeholder="XXX"
                            type="number"
                            value={store?.bank_branch_number}
                            style={{
                              border: errors?.bank_branch_number
                                ? '1px solid red'
                                : '1px solid #E5E5E5',
                            }}
                            onChange={handleChange}
                          />{' '}
                          <Input
                            name="bank_account_number"
                            placeholder="XXXXXXXXX"
                            type="number"
                            value={store?.bank_account_number}
                            style={{
                              border: errors?.bank_account_number
                                ? '1px solid red'
                                : '1px solid #E5E5E5',
                            }}
                            onChange={handleChange}
                          />
                        </div>
                        {/* <BusinessAddresses
                        store={store}
                        setStore={setStore}
                        handleChange={handleChange}
                        editAddressState={editAddressState}
                        setEditAddressState={setEditAddressState}
                      /> */}
                      </>
                    ) : (
                      <>
                        <HeadingStyled
                          className="dark-clr primary-font"
                          style={{
                            fontSize: '25px',
                            fontWeight: '700',
                            margin: '0',
                          }}
                        >
                          {store?.name_en}
                        </HeadingStyled>
                        <HeadingStyled
                          className="dark-clr"
                          style={{
                            fontSize: '15px',
                            fontWeight: '400',
                            margin: '0 0 20px 0',
                          }}
                        >
                          <i> {store?.slogan}</i>
                        </HeadingStyled>
                        <hr className="mb-3"></hr>
                        <Row>
                          <Col sm={6}>
                            <HeadingStyled
                              className="dark-clr primary-font"
                              style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                margin: '0 0 6px 0',
                              }}
                            >
                              <RiTimer2Line
                                className="inline-block"
                                style={{
                                  marginLeft: 0,
                                  marginRight: 5,
                                }}
                              />
                              Opening hours
                            </HeadingStyled>
                            <HeadingStyled
                              style={{
                                fontSize: '15px',
                                fontWeight: '400',
                                margin: '0 0 20px 0',
                                whiteSpace: 'pre-wrap',
                              }}
                              className="dark-clr"
                            >
                              {getOpeningHours(currentStore)}
                            </HeadingStyled>
                          </Col>
                          <Col sm={6}>
                            <HeadingStyled
                              style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                margin: '0 0 6px 0',
                              }}
                              className="dark-clr primary-font"
                            >
                              {' '}
                              <RiMapPin2Line
                                className="inline-block"
                                style={{
                                  marginLeft: 0,
                                  marginRight: 5,
                                }}
                              />
                              Location
                            </HeadingStyled>
                            <HeadingStyled
                              style={{
                                fontSize: '15px',
                                fontWeight: '400',
                                margin: '0 0 20px 0',
                              }}
                              className="dark-clr"
                            >
                              {currentStore?.address[0]?.address_line_1},{' '}
                              {currentStore?.address[0]?.address_line_2},{' '}
                              {currentStore?.address[0]?.town.name_en},{' '}
                              {currentStore?.address[0]?.city.name_en}
                            </HeadingStyled>
                            <HeadingStyled
                              className="d-flex flex-wrap mb-1"
                              style={{
                                fontSize: '16px',
                                fontWeight: '600',
                              }}
                            >
                              <RiBox1Line
                                className="inline-block"
                                style={{
                                  marginLeft: 0,
                                  marginRight: 5,
                                }}
                              />
                              Free Shipping Threshold
                            </HeadingStyled>
                            <HeadingStyled
                              className="dark-clr"
                              style={{
                                whiteSpace: 'pre-wrap',
                                fontSize: '15px',
                                fontWeight: '400',
                                margin: '0 0 20px 0',
                              }}
                            >
                              {formatPrice(store.free_shipping_threshold)}
                            </HeadingStyled>

                            <HeadingStyled
                              className="d-flex flex-wrap mb-1"
                              style={{
                                fontSize: '16px',
                                fontWeight: '600',
                              }}
                            >
                              <RiHourglassLine
                                className="inline-block"
                                style={{
                                  marginLeft: 0,
                                  marginRight: 5,
                                }}
                              />
                              Order Preparation Hours
                            </HeadingStyled>
                            <HeadingStyled
                              className="dark-clr"
                              style={{
                                whiteSpace: 'pre-wrap',
                                fontSize: '15px',
                                fontWeight: '400',
                                margin: '0 0 20px 0',
                              }}
                            >
                              {store.order_preparation_hours} hours
                            </HeadingStyled>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <HeadingStyled
                              className="d-flex flex-wrap mb-1"
                              style={{
                                fontSize: '16px',
                                fontWeight: '600',
                              }}
                            >
                              <RiInformation2Line
                                className="inline-block"
                                style={{
                                  marginLeft: 0,
                                  marginRight: 5,
                                }}
                              />
                              Store Description
                            </HeadingStyled>
                            <HeadingStyled
                              className="dark-clr"
                              style={{
                                whiteSpace: 'pre-wrap',
                                fontSize: '15px',
                                fontWeight: '400',
                                margin: '0 0 20px 0',
                              }}
                            >
                              {store.bio}
                            </HeadingStyled>
                          </Col>
                          <Col>
                            <div>
                              <h3 className="text-lg font-medium">
                                <RiMoneyDollarCircleLine
                                  className="inline-block"
                                  style={{
                                    marginLeft: 0,
                                    marginRight: 5,
                                  }}
                                />
                                Accepted Payments
                              </h3>
                              <div className="flex space-x-4">
                                {store.accepts_mobile_payments && (
                                  <span className="bg-gray-200 flex rounded p-2 font-normal">
                                    <LuNfc
                                      className="mt-1"
                                      color={Colors.greenMain}
                                      style={{
                                        marginLeft: 0,
                                        marginRight: 5,
                                      }}
                                    />
                                    Mobile
                                  </span>
                                )}
                                {store.accepts_credit_card_payments && (
                                  <span className="bg-gray-200 flex rounded p-2  font-normal">
                                    <FaRegCreditCard
                                      className="mt-1"
                                      color={Colors.greenMain}
                                      style={{
                                        marginLeft: 0,
                                        marginRight: 5,
                                      }}
                                    />
                                    Card
                                  </span>
                                )}
                                {store.accepts_cash_payments && (
                                  <span className="bg-gray-200 flex rounded p-2  font-normal ">
                                    <BsCashCoin
                                      className="mt-1"
                                      color={Colors.greenMain}
                                      style={{
                                        marginLeft: 0,
                                        marginRight: 5,
                                      }}
                                    />
                                    Cash
                                  </span>
                                )}
                                {store.accepts_cheques_payments && (
                                  <span className="bg-gray-200 flex rounded p-2  font-normal">
                                    <LiaMoneyCheckAltSolid
                                      className="mt-1"
                                      color={Colors.greenMain}
                                      style={{
                                        marginLeft: 0,
                                        marginRight: 5,
                                      }}
                                    />
                                    Cheques
                                  </span>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                  {editState && (
                    <div className="non-edit pb-3">
                      <Heading>Essential Data</Heading>
                      <div
                        className="flex flex-col justify-center"
                        style={{
                          gap: '10px',
                          fontFamily: Fonts.secondary,
                          padding: '10px',
                          borderRadius: '10px',
                          border: '1px solid #E5E5E5',
                          color: Colors.greenAlternate,
                          fontSize: '1rem',
                          marginBottom: '20px',
                        }}
                      >
                        <div className="flex  flex-row justify-between">
                          <p>Owner Full Name</p>
                          <p>{user.name || 'N/A'}</p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Owner ID Number</p>
                          <p>{user.id_number || 'N/A'}</p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Business Phone</p>
                          <p>{store.business_phone || 'N/A'}</p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Business Name</p>
                          <p>
                            {store.name_en + ' | ' + store.name_ar || 'N/A'}
                          </p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Business Email</p>
                          <p>{store.business_email || 'N/A'}</p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Business Bank</p>
                          <p>{formatStoreBankDetails(store) || 'N/A'}</p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Business Tax Id</p>
                          <p>{store.tax_id || 'N/A'}</p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Business Tax Type</p>
                          <p>
                            {businessTaxTypeMap[
                              store.tax_type as keyof typeof businessTaxTypeMap
                            ] || 'N/A'}
                          </p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Business Deduction-at-Source</p>
                          <p>
                            {store.deduction_at_source !== undefined
                              ? '%' + store.deduction_at_source * 100
                              : 'N/A'}
                          </p>
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Owner ID</p>
                          <Icon
                            icon="mdi:attachment"
                            color="green"
                            width={20}
                            onClick={() => {
                              window.open(
                                store.business_owner_image
                                  ?.get_object_presigned_url,
                                '_blank',
                              );
                            }}
                          />{' '}
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Bank Account Ownership</p>
                          <Icon
                            icon="mdi:attachment"
                            color="green"
                            width={20}
                            onClick={() => {
                              window.open(
                                store.business_bank_statement
                                  ?.get_object_presigned_url,
                                '_blank',
                              );
                            }}
                          />
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>Tax Certificate</p>{' '}
                          <Icon
                            icon="mdi:attachment"
                            color="green"
                            width={20}
                            onClick={() => {
                              window.open(
                                store.business_tax_certificate
                                  ?.get_object_presigned_url,
                                '_blank',
                              );
                            }}
                          />
                        </div>
                        <div className="flex  flex-row justify-between">
                          <p>DAS Certificate</p>.
                          <Icon
                            icon="mdi:attachment"
                            color="green"
                            width={20}
                            onClick={() => {
                              window.open(
                                store.business_das_certificate
                                  ?.get_object_presigned_url,
                                '_blank',
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="contact-silal">
                        <p>
                          Contact Silal in case of incorrect information or to
                          update them
                        </p>
                      </div>
                    </div>
                  )}
                  <Col lg={12} md={12} style={{ position: 'relative' }}>
                    {editState ? (
                      <>
                        <Row className="d-flex justify-content-end align-items-center mb-2">
                          <Col xs="auto">
                            <Button
                              className=" grey"
                              onClick={() => handleCancelChange()}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col xs="auto">
                            <Button onClick={submit} color="success">
                              Save changes
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row className="d-flex justify-content-end align-items-center mb-2">
                        <Col xs="auto">
                          <Button
                            color="success"
                            onClick={() => setEditState(true)}
                          >
                            Edit Profile
                          </Button>
                        </Col>
                        <Col xs="auto">
                          <AddImagesModal />
                        </Col>
                        {/* <Button
                    bg="#05AE4B33"
                    color="#05AE4B"
                    className="w-100 hover-fill"
                    onClick={handleShowCodeModal}
                  >
                    Change admin code
                  </Button> */}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </div>
        {/* <CodeModal
          showCodeModal={showCodeModal}
          handleClose={handleCloseCodeModal}
        /> */}
        {/* <AccountFreezedModal
          showModal={showFreezeModal}
          handleClose={handleCloseFreezeModal}
        /> */}
        {/* <AddImagesModal initiallyOpen={showAddImagesModal} /> */}
      </div>
    </Wrapper>
  );
};

export default ProfilePage;

const Wrapper = styled.div`
  .profile-edit {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    gap: 10px;
    margin: 10px 10px;

    .edit {
      background-color: var(--white-color);
      padding-top:import { restaurantBg } from 'assets/images/restaurant-bg.png';
          border-radius: 10px;

    }
    .non-edit {
      background-color: var(--white-color);
      width: 32%;
      height: fit-content;
      border-radius: 10px;
      padding-top: 20px;

      .contact-silal {
        font-family: ${Fonts.secondary};
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        text-align: center;
        color: var(--secondary-color);
      }
    }
  }

  .indexes {
    font-family: ${Fonts.secondary};
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.408px;
    text-transform: uppercase;
    color: #4c7061;
  }
  .values {
    font-family: ${Fonts.secondary};
    font-weight: 600;
    font-size: 13px;
    letter-spacing: -0.408px;
    text-transform: uppercase;
  }

  .red {
    color: red;
  }
`;
