import { ChangeEvent, useState } from 'react';
import Backbutton from 'pages/auth/components/back_button';
import Wrapper from './create_new_password_screen.styled';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Input } from '@nextui-org/react';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import * as userAction from 'store/user/user_actions';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { getCurrentUserState } from 'core/hooks/use_selector';
import { toast } from 'react-toastify';
import SellerRepository from 'data/repositories/seller_repository';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button } from '@nextui-org/react';
import { BsEye, BsEyeSlash, BsEyeSlashFill } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';

function CreateNewPasswordScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading } = useSelector(getCurrentUserState);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    pass: '',
    cpass: '',
  });
  const [password, setPassword] = useState({
    pass: '',
    cpass: '',
  });
  const [error] = useState('');

  const dispatch = useDispatch();
  // password validation states
  const [char, setChar] = useState(false);
  const [letter, setLetter] = useState(false);
  const [number, setNumber] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [attemptedToSubmit, setAttemptedToSubmit] = useState(false);

  function handleRoute() {
    if (location.state === 'signUpEmailVerification') {
      navigate('/seller-info', { state: 'createNewPassword' });
    } else if (location.state === 'resetpasswordemailverify') {
      navigate('/login', { state: 'createNewPassword' });
    } else {
      navigate('/seller-info', { state: 'createNewPassword' });
    }
  }

  function handleKeyup(e: React.KeyboardEvent<HTMLInputElement>) {
    const v = e.currentTarget.value.length;

    setChar(v >= 8 && v <= 20);

    const l = /[a-zA-Z]/g;
    setLetter(e.currentTarget.value.match(l) !== null);

    const n = /[0-9]/g;
    setNumber(e.currentTarget.value.match(n) !== null);

    const s = /[!@#$%^&*)(+=._-]/g;
    setSpecialChar(e.currentTarget.value.match(s) !== null);
  }

  const handleValidations = () => {
    const errFields = {
      pass: '',
      cpass: '',
    };
    let error = false;

    Object.keys(password).map(function (keyName) {
      const value = password[keyName as keyof typeof password];

      if (keyName === 'pass') {
        if (!value) {
          errFields.pass = 'ⓘ Password is required';
          console.log(value + ' 1');
          error = true;
        }
      }

      if (keyName === 'cpass') {
        if (!value) {
          errFields.cpass = 'ⓘ Confirm password is required';
          console.log(value + ' 2');

          error = true;
        }
      }
      return null;
    });
    setErrors(errFields);
    return error;
  };

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setAttemptedToSubmit(false);
    setPassword({ ...password, [e.target.id]: e.target.value });
  }

  const handleSkip = () => {
    navigate('/seller-info', { state: 'createnewpassword' });
  };

  const createNewPassword = async () => {
    setAttemptedToSubmit(true);
    if (handleValidations()) {
      return;
    }

    let error = false;

    if (!char) {
      toast.warn('Password must be 8 to 20 characters');
      error = true;
    }
    if (!letter) {
      toast.warn('Password must contain letters');
      error = true;
    }
    if (!number) {
      toast.warn('Password must contain numbers');
      error = true;
    }
    if (!specialChar) {
      toast.warn('Password must contain special characters');
      error = true;
    }

    if (error) {
      return;
    }
    if (password.pass !== password.cpass) {
      toast.error('Password does not match');
      return;
    }
    toast.info('Submitting, please wait...');
    await SellerRepository.addPassword(password.pass, password.cpass).then(
      (res) => {
        if (!res) {
          return;
        }
        dispatch(
          userAction.actionGetSetUserProfile({
            success: () => handleRoute(),
          }),
        );
      },
    );
  };

  return (
    <BackgroundContainer>
      <Helmet>
        <title>Setup Password | Silal Merchant</title>
      </Helmet>
      <Wrapper>
        <div className="top-row container">
          <div className="title flex flex-row justify-center items-center">
            <Backbutton primaryIcon={true} />
            <h2 className="primary-font-font mx-auto">Create a new password</h2>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-lg-12 mb-3">
              <Input
                isRequired
                label="Password"
                type={showPassword ? 'text' : 'password'} // Toggles between text and password
                placeholder="Enter password"
                id="pass"
                errorMessage={errors.pass}
                isInvalid={!!errors.pass}
                color={errors.pass ? 'danger' : 'success'}
                variant="bordered"
                onChange={handleChange}
                onKeyUp={handleKeyup}
                endContent={
                  showPassword ? (
                    <BsEyeSlash
                      style={{ cursor: 'pointer', color: Colors.secondaryIcon }}
                      size={20}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <BsEye
                      style={{ cursor: 'pointer', color: Colors.secondaryIcon }}
                      size={20}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )
                }
              />
            </div>
            <div className="col-12 col-lg-12 mb-3">
              <Input
                isRequired
                label="Confirm Password"
                id="cpass"
                type={showPassword ? 'text' : 'password'} // Toggles between text and password
                placeholder="Re-enter your password"
                errorMessage={errors.cpass}
                isInvalid={!!errors.cpass}
                color={errors.cpass ? 'danger' : 'success'}
                variant="bordered"
                onChange={handleChange}
                endContent={
                  showPassword ? (
                    <BsEyeSlash
                      style={{ cursor: 'pointer', color: Colors.secondaryIcon }}
                      size={20}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <BsEye
                      style={{ cursor: 'pointer', color: Colors.secondaryIcon }}
                      size={20}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )
                }
              />
            </div>
          </div>
          <div className="password-restriction">
            <h3 style={{ fontFamily: Fonts.secondary }}>
              Your password should contain:
            </h3>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={char ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !char
                    ? 'not-contains'
                    : char
                      ? 'contains'
                      : ''
                }
              >
                8 to 20 characters
              </span>
            </p>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={letter ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !letter
                    ? 'not-contains'
                    : letter
                      ? 'contains'
                      : ''
                }
              >
                Upper and lower case letters
              </span>
            </p>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={number ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !number
                    ? 'not-contains'
                    : number
                      ? 'contains'
                      : ''
                }
              >
                Numbers
              </span>
            </p>
            <p className="restriction1">
              <span>
                <Icon
                  icon="akar-icons:circle-check"
                  className={specialChar ? 'active' : ''}
                />
              </span>
              <span
                className={
                  attemptedToSubmit && !specialChar
                    ? 'not-contains'
                    : specialChar
                      ? 'contains'
                      : ''
                }
              >
                Special characters
              </span>
            </p>
          </div>
          <div>
            {error !== '' ? <span className="text-danger">{error}</span> : null}
          </div>
          <div className="form-submit">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  onPress={handleSkip}
                  color="success"
                  variant="flat"
                  className="mt-3 w-full"
                >
                  No, I prefer passwordless login
                </Button>
                <Button
                  onPress={createNewPassword}
                  className="mt-2 w-full"
                  color={
                    !char || !letter || !number || !specialChar
                      ? 'default'
                      : 'success'
                  }
                  isDisabled={
                    !char ||
                    !letter ||
                    !number ||
                    !specialChar ||
                    !password.pass.length ||
                    !password.cpass.length ||
                    password.pass !== password.cpass
                      ? true
                      : false
                  }
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </BackgroundContainer>
  );
}

export default CreateNewPasswordScreen;
