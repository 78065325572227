import { motion } from 'framer-motion';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import * as userAction from 'store/user/user_actions';
import Spinner from 'silal_app_base_react/src/components/spinner';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Backbutton from 'pages/auth/components/back_button';
import CountrySelectForm from 'components/select_country_form';
import Wrapper from './login_screen.styled';
import Button from 'silal_app_base_react/src/components/buttons';
import { toast } from 'react-toastify';
import { CurrentContries } from 'core/constants/constants';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { Country } from 'data/types/general';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { getCurrentUserState } from 'core/hooks/use_selector';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { BackgroundContainer } from 'pages/auth/components/background_container';
import { Button as ButtonNext } from '@nextui-org/react';
import CryptoJS from 'crypto-js';
import { Input } from '@nextui-org/react';
import { APP_LINKS } from 'silal_app_base_react/src/config/constants';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';

function Loginpage() {
  const { user } = useSelector(getCurrentUserState);

  const [phoneNo, setPhoneNo] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showNumForm, setShowNumForm] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const [selectedCountry, setSelectedCountry] = useState<Country>(
    CurrentContries[0],
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading } = useSelector(getCurrentUserState);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state?.email);
      setShowNumForm(false);
    }
  }, []);

  useEffect(() => {
    // Extract encrypted email and password from the URL
    async function loadLoginCredentials() {
      const searchParams = new URLSearchParams(location.search);
      const encryptedEmail = searchParams.get('a');
      const encryptedPassword = searchParams.get('b');
      if (encryptedEmail && encryptedPassword) {
        setShowNumForm(false);
        try {
          const secretKey = (import.meta as any).env
            .VITE_CREDENTIALS_ENCRYPTION_KEY; // Same key used for encryption

          // Decrypt email and password
          const decryptedEmail = CryptoJS.AES.decrypt(
            encryptedEmail,
            secretKey,
          ).toString(CryptoJS.enc.Utf8);
          const decryptedPassword = CryptoJS.AES.decrypt(
            encryptedPassword,
            secretKey,
          ).toString(CryptoJS.enc.Utf8);

          // Set email and password in state
          setEmail(decryptedEmail);
          setPassword(decryptedPassword);

          // Automatically log in with decrypted credentials
          await loginWithEmailPassword();
        } catch (error) {
          toast.error('Failed to decrypt credentials.');
        }
      }
    }
    loadLoginCredentials();
  }, [location]);

  // animation phone number field
  const animate1 = {
    x: [-150, 0],
    opacity: [0, 1],
    transition: { duration: 0.4 },
  };

  // animation email input field
  const animate2 = {
    x: [150, 0],
    opacity: [0, 1],
    transition: { duration: 0.4 },
  };

  const sendLoginOtp = async () => {
    if (!phoneNo) {
      setPhoneError('ⓘ Please enter your Phone number.');
      toast.warn('Please enter your Phone number.');
      return;
    }

    if (
      phoneNo.length < 9 ||
      phoneNo.length > 10 ||
      !FormValidators.isPhoneNumber(phoneNo)
    ) {
      setPhoneError('ⓘ Please enter a valid Phone number.');
      toast.warn('Please enter a valid Phone number.');
      return;
    }
    await AuthenticationRepository.loginSendPhoneOTP(
      selectedCountry.value + phoneNo,
    ).then((res) => {
      if (!res) return;
      toast.success(res.msg);
      navigate('/phone-otp-verification', {
        state: {
          phoneNo: selectedCountry.value + phoneNo,
          previousPage: 'login',
          expiration_date: res.expiration_date,
        },
      });
    });
  };

  const loginWithEmailPassword = async () => {
    setErrors({
      email: '',
      password: '',
    });

    const errFields = {
      email: '',
      password: '',
    };
    if (!email) {
      if (!email) {
        errFields.email = 'ⓘ Email is Required.';
      }
      if (!FormValidators.isEmail(email)) {
        errFields.email = 'ⓘ Incorrect Email.';
      }
    }
    if (!password) {
      errFields.password = 'ⓘ Password is Required.';
    }

    if (errFields.email || errFields.password) {
      return setErrors(errFields);
    }

    const data = {
      email,
      password,
    };
    const payload = {
      data,
      success: async () => {
        if (user?.enabled_mfa) {
          navigate('/mfa');
        }
      },
      failure: (error: any) => {
        toast.error(error);
      },
    };

    dispatch(userAction.actionLoginWithEmailPassword(payload));
  };

  const handleEmailOtp = async () => {
    setErrors({
      email: '',
      password: '',
    });

    const errFields = {
      email: '',
      password: '',
    };
    if (!email) {
      errFields.email = 'ⓘ Email is Required.';
    } else if (!FormValidators.isEmail(email)) {
      errFields.email = 'ⓘ Incorrect Email.';
    }

    if (errFields.email) {
      return setErrors(errFields);
    }

    await AuthenticationRepository.loginSendEmailOTP(email).then((res) => {
      if (!res) return;
      toast.success(res.msg);
      navigate('/email-otp-verification', {
        state: {
          previousPage: 'login',
          email: email,
          expiration_date: res.expiration_date,
        },
      });
    });
  };

  function BottomSection() {
    return (
      <div className="bottom-row container">
        <div className="form-submit">
          {loading ? (
            <Spinner />
          ) : (
            <div>
              {showNumForm ? (
                <ButtonNext
                  className={'w-100'}
                  color="success"
                  onClick={sendLoginOtp}
                >
                  Continue
                </ButtonNext>
              ) : (
                <div>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <ButtonNext
                        className={'w-100 mt-0 mb-2 h-11'}
                        onClick={handleEmailOtp}
                        color="success"
                        variant="flat"
                      >
                        Use Email OTP Instead
                      </ButtonNext>
                      <ButtonNext
                        className={'w-100 h-11'}
                        onClick={loginWithEmailPassword}
                        color="success"
                      >
                        Log in
                      </ButtonNext>
                    </>
                  )}
                </div>
              )}
              <h6 className="mt-2">
                By continuing, you agree to the{' '}
                <Link
                  to={APP_LINKS.policies.terms_of_service_and_privacy_policy}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      color: Colors.greenMain,
                    }}
                  >
                    Terms of Service & Privacy Policy
                  </span>
                </Link>{' '}
                and the latest version of the
                <span
                  style={{
                    fontWeight: 500,
                    color: Colors.greenAlternate,
                  }}
                >
                  {' '}
                  Merchants contract{' '}
                </span>
              </h6>
            </div>
          )}
        </div>
        {!showNumForm && (
          <div className="forgot-pass-text">
            <p>
              Trouble signing in?
              <Link to="/forgot-password">Forgot password</Link>
            </p>
          </div>
        )}
      </div>
    );
  }
  return (
    <BackgroundContainer>
      <Helmet>
        <title>Login | Silal Merchant</title>
      </Helmet>
      <div
        className="container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          baselineShift: 'center',
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={showNumForm ? animate1 : animate2}
        >
          <Wrapper>
            <div className="top-row row">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  baselineShift: 'center',
                }}
              >
                <div className="go-back-button">
                  <Backbutton primaryIcon={true} url="/welcome" />
                </div>
                <div className="title">
                  <h1>Log In</h1>
                </div>
                <div
                  className="go-back-button"
                  style={{ visibility: 'hidden' }}
                >
                  <Backbutton primaryIcon={true} />
                </div>
              </div>
              <div className="buttonGroups">
                <Button
                  text="Phone Number"
                  onClick={() => {
                    setShowNumForm(true);
                  }}
                  color={!showNumForm ? 'grey' : '#fff'}
                  bg={!showNumForm ? 'transparent' : Colors.greenMain}
                  style={{
                    fontSize: '14px',
                  }}
                />
                <Button
                  text="Email"
                  onClick={() => {
                    setShowNumForm(false);
                  }}
                  bg={showNumForm ? 'transparent' : Colors.greenMain}
                  color={showNumForm ? 'grey' : '#fff'}
                  style={{
                    fontSize: '14px',
                  }}
                />
              </div>
              <div className="form">
                {showNumForm ? (
                  <div className="form-control-mobile">
                    <FormControl htmlFor="PhoneNumber" />
                    <div
                      className="row"
                      style={{
                        border: 'none',
                        alignItems: 'center',
                      }}
                    >
                      <div className="col-12 col-lg-5 mb-2">
                        <CountrySelectForm
                          value={selectedCountry}
                          setSelectedCountry={(value) =>
                            setSelectedCountry(value)
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-7 mb-2 pl-0">
                        <Input
                          isRequired
                          value={phoneNo}
                          variant="bordered"
                          type="text"
                          label="Phone Number"
                          autoComplete="tel"
                          placeholder="0541234567"
                          color={phoneError ? 'danger' : 'success'}
                          isInvalid={!!phoneError}
                          errorMessage={phoneError}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numbers and enforce a max length of 10 characters
                            if (!/^\d*$/.test(value)) return; // Prevent non-numeric input
                            if (value.length > 10) return; // Limit to 10 characters
                            setPhoneNo(value);
                          }}
                          maxLength={10} // Ensure a hard max-length is enforced
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        color: 'grey',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {phoneNo.length + '/10'}
                    </div>
                    {BottomSection()}
                  </div>
                ) : (
                  <div className="form-control-email">
                    <div className="row">
                      <Input
                        isRequired
                        type="email"
                        label="Email"
                        autoComplete="email"
                        value={email}
                        color={errors.email ? 'danger' : 'success'}
                        variant="bordered"
                        errorMessage={errors.email}
                        isInvalid={!!errors.email}
                        placeholder="email.example@gmail.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="row">
                      <div className="password">
                        <Input
                          isRequired
                          label="Password"
                          type={showPass ? 'text' : 'password'}
                          value={password}
                          variant="bordered"
                          placeholder="Enter password"
                          color={errors.password ? 'danger' : 'success'}
                          errorMessage={errors.password}
                          isInvalid={!!errors.password}
                          onChange={(e) => setPassword(e.target.value)}
                          endContent={
                            showPass ? (
                              <BsEyeSlash
                                style={{
                                  cursor: 'pointer',
                                  color: Colors.secondaryIcon,
                                }}
                                size={20}
                                onClick={() => setShowPass(!showPass)}
                              />
                            ) : (
                              <BsEye
                                style={{
                                  cursor: 'pointer',
                                  color: Colors.secondaryIcon,
                                }}
                                size={20}
                                onClick={() => setShowPass(!showPass)}
                              />
                            )
                          }
                        />
                      </div>
                      {BottomSection()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Wrapper>
        </motion.div>
      </div>
    </BackgroundContainer>
  );
}

export default Loginpage;
